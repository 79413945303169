import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import SmartContainer from '../layout/SmartContainer';

const KnowtifiVsTraditional = ( props:any ) => {
    const { } = props;

    return (
        <div>
            <div id="splash-sub" className="small versus">
                <div className="article-blur">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">knowtifi vs. Traditional Alert Systems</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
                </div>
            </div>
            <div className="bg-gray section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{marginBottom:'30px'}}><span className="k">Rest. Assured.</span></h2>
                            <div className="columns-2">
                                <p>The technology of traditional personal alert or home alarm systems stretches back
                                    decades and, in
                                    today’s fast-paced, connected world, are often antiquated and fail to meet the needs
                                    of busy
                                    families.</p>

                                <p>Today, many families are taking care of both elderly parents and younger children at
                                    the same
                                    time. And time is of the essence, especially when it comes to being informed about
                                    their
                                    safety.</p>

                                <p>With the knowtifi app, however, you can rest assured your family’s safety is in your
                                    hands and in
                                    your control. Once the knowtifi app is activated, 911 is immediately contacted – no
                                    third-party
                                    home alarm companies screening your emergency call (that’s yesterday’s
                                    technology!).</p>

                                <p>Instead, 911 is immediately contacted and, at the same time, your trusted list of
                                    family members,
                                    friends or neighbors also is immediately alerted. <b><i>No more waiting and
                                        wondering.</i></b></p>

                                <p><b>Now, with knowtifi, help can be faster.</b></p>

                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{marginBottom:'30px'}}><span className="k">Why Pay More?</span></h2>
                            <p>If you’re paying for a home alert or alarm system, why are you waiting for help? Every
                                time your
                                alarm is engaged, a call goes to a call center somewhere where an operator “determines”
                                your
                                emergency – only then are first responders dispatched.</p>

                            <p>Safety can’t wait. See the economic and safety differences – and the advantages – of the
                                knowtifi
                                app:</p>

                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">&nbsp;</th>
                                    <th scope="col" className="highlight">knowtifi</th>
                                    <th scope="col">Ring</th>
                                    <th scope="col">ADT</th>
                                    <th scope="col">SimpliSafe</th>
                                </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                <tr>
                                    <th scope="row">Installation</th>
                                    <td className="highlight">DIY</td>
                                    <td>DIY</td>
                                    <td>Professional</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">Starts At</th>
                                    <td className="highlight">FREE</td>
                                    <td>$3.00/month</td>
                                    <td>$28.99/month</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">Up-Front Costs</th>
                                    <td className="highlight">$199.00</td>
                                    <td>Starts at $99.99</td>
                                    <td>$125.00</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">Contract</th>
                                    <td className="highlight">Month-to-Month</td>
                                    <td>Month-to-Month</td>
                                    <td>30 Months</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">Control Lights</th>
                                    <td className="highlight">Yes</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">App Pro Version</th>
                                    <td className="highlight">$1.99/month</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">Direct 911 Alert</th>
                                    <td className="highlight">Yes</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row">Contacts Alert</th>
                                    <td className="highlight end">Yes</td>
                                    <td>No</td>
                                    <td>No</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(KnowtifiVsTraditional);

