import SmartContainer from "../layout/SmartContainer";
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import productBundle from '../assets/img/911Switch-product-bundle-transparent.png';
import { useForm } from 'react-hook-form';
import BuyForm from './BuyForm';
import { useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const _ = require('lodash');

export default function Buy() {


    const stripePromise = loadStripe('pk_test_nvkIJzdABJ3G0UlKwCOpxPXc');

    return (
        <>
            <Helmet>
                <meta name="description" content="knowtifi's mission and values."/>
                <title>knowtifi : Buy 911Switch</title>
                <link rel="canonical" href="https://knowtifi.com/911switch/buy"/>
            </Helmet>
            <div id="splash-sub" className="small buynow">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">Buy 911Switch</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-5">
                            <img src={productBundle} className="img-fluid"/>
                            <br/><br/><br/>

                        </div>
                        <div className="col-7">
                            <h5>Product Description:</h5>
                            <p className="text-up">
                                The 911Switch, patented smart switch, is designed to replace a user's existing light
                                switch which controls the existing exterior lights. This 911Switch is designed to
                                look and operate just like any standard wall switch would, up for "on" and down
                                for "off". The unique feature is that it can be accessed or controlled by a
                                stand-alone key fob (sold with switch) or through the knowtifi* app. When
                                activated by fob or app, this will cause the connected fixtures to flash or blink,
                                increasing the homes visibility and identification for first responders, in the
                                event of an emergency.
                            </p>
                            <p><b>Included in the package:</b> 911Switch light switch, hub, key fob.<br/>&nbsp;</p>
                            <h5>Product Specs</h5>
                            <ul>
                                <li>Replaces any standard single pole or 3way switch</li>
                                <li>Compatible with LED, CFL, FLR, INC/HAL, MLV</li>
                                <li>No common wire necessary for switch installation</li>
                                <li>WiFi required for knowtifi app integration</li>
                                <li>Fob range up to 750 feet</li>
                                <li>Color: White</li>
                                <li>300W/VA 120V/AC/c.a./CA LED, CFL</li>
                                <li>600W/VA 120V/AC/c.a./CA NC/HAL, ELV, MLV</li>
                                <li>Model # FR2101-C-W</li>
                                <li>Installation and testing instructions included</li>
                            </ul>
                            {/*<h5>When paired with the knowtifi mobile app:</h5>*/}
                            {/*<ul>*/}
                            {/*    <li>911Switch can be activated from the knowtifi app.</li>*/}
                            {/*    <li>911Switch activates when you call 911 from the knowtifi app.</li>*/}
                            {/*    <li>When activated, the knowtifi app will alert your emergency contacts.</li>*/}
                            {/*</ul>*/}
                            {/*<h5>What's Included:</h5>*/}
                            {/*<ul>*/}
                            {/*    <li>911Switch light switch</li>*/}
                            {/*    <li>Hub</li>*/}
                            {/*    <li>Key Fob</li>*/}
                            {/*    <li>knowtifi smartphone app</li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                </SmartContainer>
            </div>

            <div className="bg-gray section-padding">
                <Elements stripe={stripePromise}>
                    <BuyForm />
                </Elements>
            </div>

            {/*<div className="section-padding">*/}
            {/*    <SmartContainer>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-12">*/}
            {/*                <h2 className="k">911Switch Specs</h2>*/}
            {/*                <ul>*/}
            {/*                    <li>Replaces any standard single pole or 3way switch</li>*/}
            {/*                    <li>Compatible with LED, CFL, FLR, INC/HAL, MLV</li>*/}
            {/*                    <li>Once installed, the FOB (included) can activate the switch up to 750 feet</li>*/}
            {/*                    <li>Installation and testing instructions included</li>*/}
            {/*                    <li>No common wire necessary for switch installation</li>*/}
            {/*                    <li>WiFi needed to activate 911Switch and hub via knowtifi app</li>*/}
            {/*                    <li>Color: White</li>*/}
            {/*                    <li>300W/VA 120V/AC/c.a./CA LED, CFL</li>*/}
            {/*                    <li>600W/VA 120V/AC/c.a./CA NC/HAL, ELV, MLV</li>*/}
            {/*                    <li>Model # FR2101-C-W</li>*/}

            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </SmartContainer>*/}

            {/*</div>*/}

                {/*<div className="section-padding">*/}
                {/*    <SmartContainer>*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-12">*/}
                {/*                <h2 className="k">911Switch Hub Required for knowtifi App Enabled Features</h2>*/}
                {/*                <h5>When activated and paired with the 911Switch, the knowtifi App offers the following functions:</h5>*/}
                {/*                <ul>*/}
                {/*                    <li>Paired 911Switch can be activated by your smart phone via our free knowtifi app.</li>*/}
                {/*                    <li>Open the knowtifi app and press 911 button to call and activate</li>*/}
                {/*                    <li>When activated knowtifi app will send text and/or app alert notifications to emergency contacts</li>*/}
                {/*                    <li>When 911 is dialed the knowtifi app automatically activates your 911Switch and connected lights begin to flash</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </SmartContainer>*/}

                {/*</div>*/}
            {/*</div>*/}
        </>
    );
}
