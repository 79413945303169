import { CLEAR_SETTINGS } from '../types';

const initialState: any = {
    appName: 'Fairweather',
    appVersion: '1.0.0',
}

const settings = (state = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case CLEAR_SETTINGS:
            return initialState;

        default:
            return state;
    }
}

export default settings;
