import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import SmartContainer from '../layout/SmartContainer';
import { countrySelect, stateSelect, usd } from './helpers';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useForm } from 'react-hook-form';

const _ = require('lodash');

const BuyForm = ( props:any ) => {
    const { } = props;

    const stripe: any = useStripe();
    const elements: any = useElements();

    const [formStatus, setFormStatus] = useState(-1);
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            Name: '',
            Address1: '',
            Address2: '',
            City: '',
            State: '',
            Zip: '',
            Phone: '',
            Email: '',
        }
    });
    const [cartTotals, setCartTotals] = useState<any>({
        qty: 0,
        subtotal: 0,
        shipping: 0,
        tax: 0,
        total: 0
    });

    const submitForm = () => {

    }

    const costPerSwitch = 100;

    const updateCart = (newQty: string) => {
        let qty = 0;
        if (newQty === '') {
            setCartTotals({ qty: '', subtotal: 0, shipping: 0, tax: 0, total: 0 })
        } else {
            qty = parseInt(newQty);
            if (isNaN(qty)) {
                updateCart('');
            } else {
                const tmpCart = _.cloneDeep(cartTotals);
                tmpCart.qty = qty;
                tmpCart.subtotal = qty * costPerSwitch;
                tmpCart.shipping = qty * 10;
                tmpCart.tax = tmpCart.subtotal * 0.07;
                tmpCart.total = tmpCart.subtotal + tmpCart.shipping + tmpCart.tax;
                setCartTotals(tmpCart);
            }
        }
    }

    // useEffect(() => {
    //     // Create PaymentIntent as soon as the page loads
    //     window
    //         .fetch(accoApiUrl()+"/stripe_intent.php", {
    //             method: "POST",
    //             headers: { "Content-Type": "application/json" },
    //             body: ''
    //         })
    //         .then(res => { return res.json(); })
    //         .then(data => { setClientSecret(data.clientSecret); });
    // }, []);

    const cardStyle = {
        style: {
            base: {
                color: '#212121',
                '::placeholder': {
                    color: '#6C757D',
                },
                backgroundColor: '#FFFFFF',
                fontSize: '18px',
                marginTop: '7px',
                paddingBottom: '7px',

                paddingLeft: '14px',
                paddingRight: '14px',
            },
            invalid: {
                color: '#e20307',
            },
        },
    };

    const handleChange = async (event: any) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        // setDisabled(event.empty);
        // setError(event.error ? event.error.message : "");
    };

    return (
        <SmartContainer>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className="row checkout-form">
                    <div className="col-sm-12">
                        <h1 className="k" style={{ marginBottom: '20px' }}>Buy 911Switch Now</h1>
                        {/*<h3 className="buytitle">911Switch : <span>{ usd(costPerSwitch) }</span> <br/>&nbsp;</h3>*/}
                    </div>
                    <div className="col-md-6">
                        <div id="cart">
                            <table width="100%">
                                <tbody>
                                <tr>
                                    <td><h5>Product</h5></td>
                                    <td align="right"><h5>Qty</h5></td>
                                </tr>
                                <tr id="tblQty">
                                    <td className="form-bookname">911Switch @ {usd(costPerSwitch)}ea</td>
                                    <td align="right">
                                        <input type="text" value={cartTotals.qty}
                                               style={{ width: '60px', textAlign: 'center' }}
                                               className={"form-control"}
                                               onChange={(e: any) => updateCart(e.target.value)}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <hr/>
                            <table width="100%">
                                <tbody>
                                <tr id="tblSubtotal">
                                    <td>Subtotal:&nbsp;&nbsp;&nbsp;</td>
                                    <td align="right"><span
                                        id="cartsubtotal">{usd(cartTotals.subtotal)}</span></td>
                                </tr>
                                <tr id="tblShipping">
                                    <td>Shipping&nbsp;Cost:&nbsp;&nbsp;&nbsp;</td>
                                    <td align="right"><span id="cartship">{usd(cartTotals.shipping)}</span>
                                    </td>
                                </tr>
                                <tr id="tblTax">
                                    <td>Sales Tax: &nbsp;</td>
                                    <td align="right"><span id="carttax">{usd(cartTotals.tax)}</span></td>
                                </tr>
                                </tbody>
                            </table>
                            <hr/>
                            <table width="100%">
                                <tbody>
                                <tr id="tblTotal">
                                    <td>Total: &nbsp;</td>
                                    <td align="right"><span id="carttotal">{usd(cartTotals.total)}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <h4 style={{marginTop:'50px'}}>Billing Information</h4>
                            <div className="form-group">
                                <label>Email Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={'you@example.com'}
                                    {...register("Email", { required: true })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={'123-456-7890'}
                                    {...register("Email", { required: true })}
                                />
                            </div>
                            <div className="form-group">
                                <label>Credit Card</label>
                                    <CardElement id="card-element" options={cardStyle} onChange={handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h5>Shipping Address</h5>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={'John Doe'}
                                {...register("Name", { required: true })}
                            />
                        </div>
                        <div className="form-group">
                            <label>Address 1</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("Address1", { required: true })}
                            />
                        </div>
                        <div className="form-group">
                            <label>Address 2</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("Address2", { required: true })}
                            />
                        </div>
                        <div className="form-group">
                            <label>State</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("State", { required: true })}
                            />
                        </div>
                        <div className="form-group">
                            <label>Postal Code</label>
                            <input
                                type="text"
                                className="form-control"
                                {...register("Zip", { required: true })}
                            />
                        </div>

                        <div className="form-group">
                            <label>City</label>
                            <input type="text" name="city" id="city" className="form-control"/>
                        </div>
                        {/*<div className="form-group">*/}
                        {/*    <label>Country</label>*/}
                        {/*    <select id="country" name="country" className="form-select">*/}
                        {/*        {countrySelect}*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        <div className="form-group">
                            <label>State</label>
                            <select name="state" id="state" className="form-control">
                                {stateSelect}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Postal Code</label>
                            <input type="text" name="zip" id="zip" className="form-control"/>
                        </div>
                        <script>
                            populateCountries("country", "state");
                        </script>
                    </div>
                </div>
            </form>
        </SmartContainer>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(BuyForm);

