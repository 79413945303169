// AUTH
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';

// ALERTS
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';

export const CLEAR_SETTINGS = 'CLEAR_SETTINGS';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOAD_USER = 'LOAD_USER';
export const LOGOUT = 'LOGOUT';

export const SPINNER_OFF = 'SPINNER_OFF';
export const SPINNER_ON = 'SPINNER_ON';
