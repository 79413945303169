import React from 'react';

const Tooltip = ( props:any ) => {
    const { x, y, visible, children } = props;

    const tooltipStyle:any = {
        position: "absolute",
        top: y - 120,
        left: x - 80,
        display: visible ? "block" : "none",
        backgroundColor: "#333",
        color: "#fff",
        padding: "5px 20px",
        borderRadius: "3px",
        zIndex: 1000,
        pointerEvents: 'none', // Prevent tooltip from capturing mouse events
    };

    return (
        <div style={tooltipStyle} className="tooltip">
            {children}
        </div>
    );
};

export default Tooltip;
