import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import { useLocation, useParams } from 'react-router';
import SmartContainer from '../layout/SmartContainer';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { Helmet } from 'react-helmet';
import { Buffer } from 'buffer';
import newsPlaceholder from '../assets/img/news-placeholder.png';

const NewsItem = ( props:any ) => {
    const { } = props;

    const location = useLocation();
    const params = useParams();
    const slug = params.slug;

    const init: any = [];
    const [newsLoaded, setNewsLoaded] = useState(false);
    const [imageLoaded, setImageLoaded] = useState( false );
    const [imageData, setImageData] = useState( '' );
    const [article, setArticle] = useState(init);

    useEffect(() => {
        (async () => {
            if (!newsLoaded) {
                const q = "{ 'Payload.Slug': { $eq: '"+slug+"' } }";
                const headers = { 'headers': { 'Authorization': 'A5O4l1iHcEqLvb3ilvdm/A==' } }
                const news: any = await axios.get('/api/v2/objects/data/News?size=25&i=0&t=&sort=asc&q='+encodeURIComponent(q), headers);
                if (news && news.data && news.data.__a && news.data.__a.count > 0) {
                    setArticle(news.data.r.pop());
                }
                setNewsLoaded(true);
            } else if (!imageLoaded) {
                try {
                    const headers:any = { headers: { 'Authorization': 'A5O4l1iHcEqLvb3ilvdm/A==' }, responseType: 'arraybuffer' };
                    const imgData = await axios.get('/api/v2/objects/locker/id/' + article.Photo, headers)
                        .then((response: any) => Buffer.from(response.data, 'binary').toString('base64'));
                    setImageData(imgData);
                    setImageLoaded(true);
                } catch(err) {
                    console.log('error loading image');
                    setImageData('');
                    setImageLoaded(true);
                }
            }
        })();
    }, [newsLoaded])

    return (
        <div>
            <Helmet>
                <meta name="description" content={"knowtifi News & Events: "+ article && article.Title ? article.Title : ""} />
                <title>knowtifi : { article && article.Title ? article.Title : "News Article"}</title>
                <link rel="canonical" href={"https://knowtifi.com/news/"+slug} />
            </Helmet>
            <div id="splash-sub" className="small article"
                 style={{'backgroundImage':('url(data:image/png;base64,'+imageData+')')}}>
                <div className="article-blur">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">{ article && article.Title ? article.Title : ""}</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
                </div>
            </div>
            <div className="news-article-div">
                <SmartContainer>
                    <div className="row">
                        {article && article.Title && newsLoaded ?
                            <>
                                <div className="col-12 col-md-5">
                                    { !imageData.length ?
                                        <img src={newsPlaceholder} className="news-grid-image" />
                                        :
                                        <img src={'data:image/png;base64,'+imageData} className="news-grid-image" />
                                    }
                                </div>
                                <div className="col-12 col-md-7">
                                    <span className="date">{ moment(article.PublishDate).format('MM.DD.YYYY hh:mm a')}</span>
                                    <h3>{article.Title}</h3>
                                    { article.Subtitle.length ? <p className="text-up"><b>{article.Subtitle}</b></p> : <></> }
                                    <div dangerouslySetInnerHTML={{__html: article.Body}} />
                                </div>
                            </>
                            : !article.length && newsLoaded ?
                                <div className="col-12">
                                    <div className="alert alert-warning">
                                        <b>Article cannot be found.</b>
                                    </div>
                                </div>
                            : !newsLoaded ?
                                    <div className="col-12 text-center">
                                        <i className="fa-duotone fa-spinner-third fa-spin loading-div"></i>
                                    </div>
                            : <></>
                        }
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(NewsItem);

