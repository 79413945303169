import React, { useEffect, useRef, useState } from 'react';
import phoneframe from '../assets/img/phone-frame.png';
import looper from '../assets/vid/knowtifilooper2.mp4';
import Tooltip from '../screens/Tooltip';


const KnowtifiIntro = ( props:any ) => {
    const { video } = props;

    const [appClicked, setAppClicked] = useState( false );
    const [peaceOut, setPeaceOut] = useState( false );
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const vidRef:any = useRef();
    const vidRef2:any = useRef();
    const [tooltip, setTooltip] = useState({
        x: 0,
        y: 0,
        visible: false,
        content: '',
    });
    const handleMouseMove = (e:any) => {
        setTooltip((prev) => ({
            ...prev,
            x: e.clientX,
            y: e.clientY,
        }));
    };

    const handleMouseEnter = () => {
        setTooltip((prev) => ({
            ...prev,
            visible: true,
            content: 'Click to <br/> Activate knowtifi!',
        }));
    };

    const handleMouseLeave = () => {
        setTooltip((prev) => ({
            ...prev,
            visible: false,
        }));
    };

    //useEffect(() => { vidRef.current.play(); },[]);

    const playvideo = () => {
        vidRef.current.play();
        vidRef2.current.play();
        setAppClicked(true);
        setTimeout( () => setPeaceOut(true), 5000);
        setTimeout( () => setPeaceOut(false), 35000);
    }

    const toggleDisplay = () => {
        if (appClicked) {
            setPeaceOut( !peaceOut );
        } else {
            playvideo();
        }
    }

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize)
    }, []);

    return (
        <div id="splash-newhome"
             onMouseMove={handleMouseMove}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             onClick={()=>toggleDisplay()} className="home-intro">

                <video className={"fit-height"} ref={ vidRef2 }
                       src={video} autoPlay={false}
                       controls={false} muted={true}
                       loop={true} playsInline={true}>911 Switch Flashing Lights</video>

            <div className="container home-intro-video-text">
                <div className="row">
                    <div className="col-4">
                        <div id={'splash-phone'} onClick={()=>playvideo()} className={'thephone ' + (peaceOut ? 'peaceout' : '')}>
                            <video ref={ vidRef } src={looper} autoPlay={false} loop={false}
                                   controls={false} muted={true} playsInline={true}>knowtifi app demo</video>
                            <img className="phoneframe" src={phoneframe} />
                        </div>
                    </div>
                    <div className={"col-8 home-intro-video-text-container " + (peaceOut ? 'peaceout' : '')}>
                        <h1>Having an Emergency?</h1>
                        <div><strong>Just press the 911 button in the knowtifi app and</strong>
                            <ul>
                                <li>911 is dialed on your phone</li>
                                <li>your home's exterior lights begin to blink</li>
                                <li>your emergency contacts are notified immediately</li>
                            </ul>
                            <button className={'btn btn-danger'} onClick={()=>playvideo()}><i
                                className="fa-sharp fa-solid fa-circle-arrow-right"></i> &nbsp;Click to see knowtifi at work!</button>
                        </div>
                    </div>
                </div>
            </div>
            { !appClicked &&
              <Tooltip x={tooltip.x} y={tooltip.y} visible={tooltip.visible}>
                  Click to<br/>Activate knowtifi!
              </Tooltip>
            }
        </div>
    )
}

export default KnowtifiIntro;

