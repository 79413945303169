import React, { useState, useEffect } from 'react';
import SmartContainer from '../layout/SmartContainer';
import { connect } from 'react-redux';
import lightFlash from '../assets/vid/knowtifi-light-flash.mp4';

import happyCouple from '../assets/img/img-elderly-couple.jpg';
import productBundle from '../assets/img/911Switch-product-bundle-transparent.png';
import VideoBackground from '../layout/VideoBackground';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const S911switch = (props: any) => {
    const {} = props;

    return (
        <div>
            <Helmet>
                <meta name="description" content="The 911Switch, our patented smart switch, is designed to turn your house exterior lights into a flashing beacon for first responders during an emergency." />
                <title>knowtifi : 911Switch</title>
                <link rel="canonical" href="https://knowtifi.com/911switch"/>
            </Helmet>
            <div id="splash-sub" className="switch">
                <VideoBackground video={lightFlash} />
                <SmartContainer>
                    <div className="row">
                        <div className="col-xs-12 col-sm-10 offset-sm-1 col-md-7 offset-md-5 splash-sub-content">
                            <div className="splash-text">
                                <div className="k bot-10">
                                    <h2 style={{ marginBottom: '0px' }}>911Switch</h2>
                                    <span>Powered by knowtifi</span>
                                </div>
                                <div className="up">
                                    <p>The 911Switch, our patented smart switch, is designed to turn your house exterior
                                        lights into a flashing beacon for first responders during an emergency.</p>
                                    <p><Link to={"/911switch/buy"} className="btn btn-danger">Buy
                                        Now &nbsp; <i className="fa-sharp fa-solid fa-right"></i></Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="bg-gray section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h2 style={{ marginBottom: '10px' }}><span className="k">911 Switch</span></h2>
                            <h3 style={{ marginBottom: '30px' }}>Lights the Way to Safety</h3>

                            <div>
                                <p>911Switch powered by knowtifi, the first and only, patented smart light switch designed to help you in an emergency, can be easily installed and activated immediately when you deploy the knowtifi app – telling emergency responders you need help and see you.</p>
                                <p>In addition to your trusted contacts being notified by the knowtifi app, adding a 911Switch – a rapid alert light – alerts emergency crews and others who can render aid. In an emergency, visibility is crucial for first responders.</p>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-6">
                            <img src={happyCouple} className="img-fluid"/>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <img src={productBundle} className="img-fluid"/>
                            <p>&nbsp;</p>
                            <p className="pricing"><span className="strike">&nbsp;$249&nbsp;</span> &nbsp; <span
                                className="discount">$199</span> / Bundle</p>
                            <Link to="/911switch/buy" className="big-btn"><i
                                className="fa-solid fa-bag-shopping"></i> &nbsp; Buy Now</Link>
                        </div>
                        <div className="col-12 col-md-7">
                            <h2 style={{ marginTop: '20px', marginBottom: '20px' }}><span
                                className="k">Easy to Install &amp; No Monthly Fees!</span></h2>
                            <div>
                                <p>Installation is easy. The 911Switch can be installed just like a standard light
                                    switch – most DIY homeowners can do it in minutes – replacing an existing,
                                    low-voltage light switch that operates your standard household lamps.</p>
                                <p>In addition to your trusted contacts being notified by the knowtifi app, the rapid
                                    flashing of the 911Switch light helps alert neighbors, who also be able to render
                                    aid. That’s not by accident. That’s by design. In an emergency, visibility is a
                                    crucial thing for first responders. They need to see and know where the emergency
                                    is.</p>
                                <p>For years, homeowners have relied on an audible alert (siren, horn, etc.), but an
                                    audible signal is only good for inside your home. The visibility of the 911Switch
                                    changes the game. The 911Switch makes safety visible for first responders so they
                                    can save precious time and help you or your family faster and safer.</p>
                                <p>Want more than one 911Switch? No problem. The 911Switch features a proprietary,
                                    encrypted signal initiated by the knowtifi app and is offered as individual light
                                    switch units or multiple units depending on the need.</p>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(S911switch);

