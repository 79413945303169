import React, { useEffect, useState } from 'react';

const SmartContainer = ( props:any ) => {
    const { children } = props;

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });
        }
        window.addEventListener('resize', handleResize)
    }, []);

    return (
        <div className={"container"+(dimensions.width < 1200 ? '-fluid' : '' )}>
            { children }
        </div>
    )
}

export default SmartContainer;

