import React, { useState, useEffect } from 'react';
import newsPlaceholder from '../assets/img/news-placeholder.png';
import { connect } from 'react-redux';
import axios from 'axios';
import { Buffer } from 'buffer';

const NewsImage = ( props:any ) => {
    const { imageId } = props;

    const [imageData, setImageData] = useState( '' );

    useEffect(() => {
        (async () => {
            try {
                const headers:any = { headers: { 'Authorization': 'A5O4l1iHcEqLvb3ilvdm/A==' }, responseType: 'arraybuffer' };
                const imgData = await axios.get('/api/v2/objects/locker/id/' + imageId, headers)
                    .then((response: any) => Buffer.from(response.data, 'binary').toString('base64'));
                setImageData(imgData);
            } catch(err) {
                console.log('error loading image');
            }
        })();
    }, [imageId]);

    return ( !imageData.length ?
            <img src={newsPlaceholder} className="news-grid-image" />
            :
            <img src={'data:image/png;base64,'+imageData} className="news-grid-image" />
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(NewsImage);

