import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import SmartContainer from '../layout/SmartContainer';
import { Link } from 'react-router-dom';
import clear from '../assets/img/clear-5x7.png';
import moment from 'moment/moment';
import { Helmet } from 'react-helmet';

const Faqs = ( props:any ) => {
    const { } = props;

    const init: any = [];
    const [faqsLoaded, setFaqsLoaded] = useState(false);
    const [faqs, setFaqs] = useState(init);
    const [category, setCategory] = useState( 'knowtifi' );

    useEffect(() => {
        (async () => {
            if (!faqsLoaded) {
                const headers = { 'headers': { 'Authorization': 'A5O4l1iHcEqLvb3ilvdm/A==' } }
                let news: any = await axios.get('/api/v2/objects/data/Faqs?size=100&i=0&t=&sort=desc&q=', headers);
                if (news && news.data && news.data.__a && news.data.__a.count > 0) {
                    news.data.r.sort((a:any, b:any) => {
                        return a.rank - b.rank;
                    });
                    setFaqs(news.data.r);
                }
                setFaqsLoaded(true);
            }
        })();
    }, [faqsLoaded])

    return (
        <div>
            <Helmet>
                <meta name="description" content="Frequently asked questions regarding the knowtifi app and 911Switch" />
                <title>knowtifi : Frequently Asked Questions</title>
                <link rel="canonical" href="https://knowtifi.com/faqs"/>
            </Helmet>
            <div id="splash-sub" className="small faqs">
                <SmartContainer className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">FAQs</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="news-div">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="faq-categories">
                                <div className={"faq-cat-tab" + (category === 'knowtifi' ? ' on' : '')}
                                     onClick={()=>setCategory('knowtifi')}>knowtifi FAQs</div>
                                <div className={"faq-cat-tab" + (category === '911Switch' ? ' on' : '')}
                                     onClick={()=>setCategory('911Switch')}>911Switch FAQs</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {faqs.length && faqsLoaded ?
                            <div className="col-12">
                                <ol className={"faq-list"}>
                                    {faqs.map((faq: any) => faq.Category === category &&
                                      <li className="news-item" key={faq.__i.guid}>
                                        <h5>{faq.Question}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: faq.Answer }}></div>
                                      </li>
                                    )}
                                </ol>
                            </div>
                            : !faqs.length && faqsLoaded ?
                                <div className="col-12">
                                    <div className="alert alert-warning">
                                        <b>No FAQs to display.</b>
                                    </div>
                                </div>
                                : !faqsLoaded ?
                                    <div className="col-12 text-center">
                                        <i className="fa-duotone fa-spinner-third fa-spin loading-div"></i>
                                    </div>
                                    : <></>
                        }
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(Faqs);

