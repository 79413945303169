import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import logo from '../assets/img/logo.png';
import apple from '../assets/img/dl-apple.png';
import gplay from '../assets/img/dl-google.png';
import applesm from '../assets/img/dl-apple-sm.png';
import gplaysm from '../assets/img/dl-google-sm.png';
import { Link } from 'react-router-dom';
import SmartContainer from './SmartContainer';
import { useLocation } from 'react-router';

const SiteWrapper = (props: any) => {
    const { children } = props;

    const [navOpen, setNavOpen] = useState( false );

    const location = useLocation();
    const route = location.pathname;
    console.log('route', route);

    return (
        <>
            <header>
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="content">
                                <div id="header-logo">
                                    <Link to={'/'}>
                                        <img src={logo} id="logo" alt="knowtifi Logo"/>
                                    </Link>
                                </div>
                                <div id="header-buttons">
                                    <a href=""><img src={apple} /></a>
                                    <a href=""><img src={gplay} /></a>
                                    <Link to={'/911Switch/buy'} className={'btn btn-danger'}>Buy&nbsp;911Switch</Link>
                                </div>
                                <div id="header-buttons-small">
                                    <a href=""><img src={applesm} /></a>
                                    <a href=""><img src={gplaysm} /></a>
                                    <i className={"fa-solid fa-fw " + (navOpen ? 'fa-xmark' : 'fa-bars')} onClick={()=>setNavOpen(!navOpen)}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </header>

            <div className="spacer"></div>

            <nav>
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <ul className={navOpen ? 'navOpen' : ''}>
                                <li><Link to={'/'} className={route==='/' ? 'active':''} onClick={()=>setNavOpen(false)}>Home</Link></li>
                                <li><Link to={'/knowtifi'} className={route==='/knowtifi' ? 'active':''} onClick={()=>setNavOpen(false)}>knowtifi</Link></li>
                                <li><Link to={'/911switch'} className={route==='/911switch' ? 'active':''} onClick={()=>setNavOpen(false)}>911Switch</Link></li>

                                <li><Link to={'/mission'} onClick={()=>setNavOpen(false)}>About Us</Link>
                                    <ul>
                                        <li><Link to={'/mission'} className={route==='/mission' ? 'active':''} onClick={()=>setNavOpen(false)}>Our Mission</Link></li>
                                        <li><Link to={'/news'} className={route.includes('/news') ? 'active':''} onClick={()=>setNavOpen(false)}>News & Events</Link></li>
                                        <li><Link to={'/faqs'} className={route==='/faqs' ? 'active':''} onClick={()=>setNavOpen(false)}>FAQs</Link></li>
                                        <li><Link to={'/contact'} className={route==='/contact' ? 'active':''} onClick={()=>setNavOpen(false)}>Contact</Link></li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                    </div>
                </SmartContainer>
            </nav>

            <main>
                { children }
            </main>

            <div className="bg-red section-padding dl-cta">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1>Download Now and Get knowtifi FREE for 30 Days!</h1>
                            <a href=""><img src={apple} className="dlbtn" /></a>
                            <a href=""><img src={gplay} className="dlbtn" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="bg-gray">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-4 footlogo">
                            <img src={logo} className="logo" alt="knowtifi logo"/>

                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            <h4 className="underlined">Subscribe</h4>
                            <p className="text-down" style={{ marginBottom: '3px' }}>Enter your email to subscribe to our
                                mailing list:</p>
                            <div className="form-group horz">
                                <input type="email" className="form-control" placeholder="jdoe@example.com"/>
                                <button className="btn btn-success">Go</button>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 social">
                            <h4 className="underlined">Connect</h4>
                            <p className="text-down" style={{ marginBottom: '3px' }}>Follow us on our social media
                                channels:</p>

                            <a href="http://facebook.com/knowtifi" target="_blank"><i className="fa-brands fa-facebook-f fa-fw"></i></a>&nbsp;
                            <a href="" target="_blank"><i className="fa-brands fa-twitter fa-fw"></i></a>&nbsp;
                            <a href="https://www.instagram.com/knowtifi/" target="_blank"><i className="fa-brands fa-instagram fa-fw"></i></a>&nbsp;
                            <a href="http://www.tiktok.com/@knowtifi" target="_blank"><i className="fa-brands fa-tiktok fa-fw"></i></a>
                        </div>
                    </div>
                </SmartContainer>
                <div className="copyright">
                    Copyright © knowtifi. All Rights Reserved. | <a href="terms.php">Terms of Service</a>
                </div>
            </footer>
        </>

    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(SiteWrapper);

