import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import phoneframe from '../assets/img/phoneframe2.png';
import phonestatus from '../assets/img/phone-status.png';
import screen1 from '../assets/img/screen1.png';
import screen2 from '../assets/img/screen2.png';
import screen3 from '../assets/img/screen3.png';
import screen4 from '../assets/img/screen4.png';
import screen5 from '../assets/img/screen5.png';
import screen6 from '../assets/img/screen6.png';
import screen7 from '../assets/img/screen7.png';
import screen8 from '../assets/img/screen8.png';
import SmartContainer from '../layout/SmartContainer';
import { Helmet } from 'react-helmet';

const Knowtifi = (props: any) => {
    const {} = props;

    return (
        <div>
            <Helmet>
                <meta name="description" content="With the knowtifi mobile app, your emergency contacts are alerted the moment you activate a 911 call or live text session that includes your GPS location." />
                <title>knowtifi : The knowtifi Mobile App</title>
                <link rel="canonical" href="https://knowtifi.com/knowtifi"/>
            </Helmet>
            <div id="splash-sub" className="knowtifi">
                <SmartContainer>
                    <div className="row">
                        <div className="col-xs-12 col-sm-10 offset-sm-1 col-md-7 offset-md-5 splash-sub-content">
                            <div className="splash-text">
                                <h2 className="k">knowtifi</h2>
                                <div className="up">
                                    <p>With the knowtifi mobile app, your emergency contacts are alerted the
                                        moment you activate a 911 call or live text session that includes your GPS
                                        location.</p>
                                    {/*<p className="text-right"><a href="download.php" className="btn btn-danger">Download*/}
                                    {/*    Now &nbsp; <i className="fa-sharp fa-solid fa-right"></i></a></p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding bg-gray">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <h2 style={{ marginBottom: '10px' }}><span className="k">See How knowtifi Works!</span></h2>
                            <h3 style={{ marginBottom: '30px' }}>With knowtifi, You Can Be Informed Anywhere, Anytime</h3>

                            <div className="columns-2">
                                <p>After downloading the knowtifi app, simply add your emergency contacts, including family members, friends or neighbors and, if you activate knowtifi because of an emergency, your trusted contacts are simultaneously notified. With knowtifi, you will inform and be informed.</p>
                                <p>It’s easy to add your emergency contacts – simply with the press of a button.</p>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>

            <div className="section-padding">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">

                                <h2 style={{ marginBottom: '10px' }}><span className="k">The App You Need</span></h2>
                                <h3 style={{ marginBottom: '30px' }}>But We Hope Never Have to Use</h3>

                            <div className="columns-2">
                                <p>At knowtifi, our goal is to give you and your family the peace of mind that comes with knowing loved ones are safe. We have families too and that’s why we created the knowtifi app – to make sure emergency help is on the way and family and friends are alerted when someone needs help.</p>
                                <p>And with our 911Switch, house lights blink rapidly so emergency responders will know where the emergency is, helping them get their faster.</p>
                            </div>

                                <div className="app-features">
                                    <div className="left">
                                        <h4 className="k">Basic Features</h4>
                                        <div className="callout" id="callout-1">
                                            <div className="info">
                                                <h5>Dial 911</h5>
                                                <p>Kick off a call to 911 through the app to alert your emergency
                                                    contacts.</p>
                                            </div>
                                            <div className="icon">
                                                <i className="fa-sharp fa-solid fa-fw fa-phone-flip"></i>
                                            </div>
                                        </div>
                                        <div className="callout" id="callout-2">
                                            <div className="info">
                                                <h5>Alert Contacts</h5>
                                                <p>When you dial 911, an alert is sent to each of your emergency
                                                    contacts.</p>
                                            </div>
                                            <div className="icon">
                                                <i className="fa-sharp fa-solid fa-fw fa-bullhorn"></i>
                                            </div>
                                        </div>
                                        <div className="callout" id="callout-3">
                                            <div className="info">
                                                <h5>Precise Location</h5>
                                                <p>Relay information to 911 personnel such as your precise GPS location.</p>
                                            </div>
                                            <div className="icon">
                                                <i className="fa-sharp fa-solid fa-fw fa-street-view"></i>
                                            </div>
                                        </div>
                                        {/*<div className="callout" id="callout-4">*/}
                                        {/*    <div className="info">*/}
                                        {/*        <h5>Activate 911Switch</h5>*/}
                                        {/*        <p>If 911Switch is installed, a call to 911 will activate your connected*/}
                                        {/*            lighting systems.</p>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="icon">*/}
                                        {/*        <i className="fa-sharp fa-solid fa-fw fa-lightbulb-on"></i>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="phone">
                                        <div className="app-header">
                                            <div>9:11</div>
                                            <div><img src={phonestatus} /></div>
                                        </div>
                                        <div className="app-screens">
                                            <img src={screen1} id="screen-1" className="screenshot active"/>
                                            <img src={screen2} id="screen-2" className="screenshot"/>
                                            <img src={screen3} id="screen-3" className="screenshot"/>
                                            <img src={screen4} id="screen-4" className="screenshot"/>
                                            <img src={screen5} id="screen-5" className="screenshot"/>
                                            <img src={screen6} id="screen-6" className="screenshot"/>
                                            <img src={screen7} id="screen-7" className="screenshot"/>
                                            <img src={screen8} id="screen-8" className="screenshot"/>
                                        </div>
                                        <img src={phoneframe} className="frame"/>
                                    </div>
                                    <div className="right">
                                        <h4 className="k">Pro Features</h4>
                                        <div className="callout" id="callout-5">
                                            <div className="info">
                                                <h5>knowtifi 911 Text</h5>
                                                <p>Text with knowtifi and relay your emergency to local 911 operators.</p>
                                            </div>
                                            <div className="icon">
                                                <i className="fa-sharp fa-solid fa-fw fa-comments"></i>
                                            </div>
                                        </div>
                                        <div className="callout" id="callout-6">
                                            <div className="info">
                                                <h5>Unlimited Contacts</h5>
                                                <p>Alert each of your emergency contacts and allow them to follow along.</p>
                                            </div>
                                            <div className="icon">
                                                <i className="fa-sharp fa-solid fa-fw fa-users"></i>
                                            </div>
                                        </div>
                                        <div className="callout" id="callout-7">
                                            <div className="info">
                                                <h5>Live Transcriptions</h5>
                                                <p>Transcripts relayed to your emergency contacts and local 911 operators.</p>
                                            </div>
                                            <div className="icon">
                                                <i className="fa-sharp fa-solid fa-fw fa-file-pen"></i>
                                            </div>
                                        </div>
                                        {/*<div className="callout" id="callout-8">*/}
                                        {/*    <div className="info">*/}
                                        {/*        <h5>Location Tracking</h5>*/}
                                        {/*        <p>If you're in motion, knowtifi will keep responders alerted to your*/}
                                        {/*            position.</p>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="icon">*/}
                                        {/*        <i className="fa-solid fa-fw fa-route"></i>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                        </div>
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Knowtifi);

