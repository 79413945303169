import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import SmartContainer from '../layout/SmartContainer';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useForm } from 'react-hook-form';

const _ = require("lodash");
const moment = require('moment');

const ContactUs = (props: any) => {
    const {} = props;

    const [formStatus, setFormStatus] = useState( -1 );

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            Name: '',
            Email: '',
            Phone: '',
            Message: '',
        }
    });
    
    const submitForm = async ( formData:any ) => {
        setFormStatus(2);
        const data = {
            api_key: 'api-BD74598ECD8D11EDA0F3F23C91C88F4E',
            //to: 'David Casteel <david@911switch.com>',
            to: ['Alan Waldron <awaldron@buildinmotion.com>'],
            sender: formData.Name + " <"+formData.Email+">",
            subject: 'Knowtifi Contact Us',

            text_body:
                'New Knowtifi contact us message:.\n\n' +
                'Name: ' + formData.Name + '\n' +
                'Email: ' + formData.Email + '\n' +
                'Date: ' + moment().format('MMMM Do YYYY, h:mm:ss a') + '\n\n' +
                'Message:\n' + formData.Message + '\n\n\n',

            html_body:
                '<h2>New Knowtifi Contact Us Message:</h2>' +
                '<p><b>Name:</b> ' + formData.Name +
                '<br/><b>Email:</b> ' + formData.Email +
                '<br/><b>Date:</b> ' + moment().format('MMMM Do YYYY, h:mm:ss a') + '</p>' +
                '<p><b>Name:</b><br/> ' + formData.Message + '</p>',

            custom_headers: [{
                header: 'Reply-To',
                value: formData.Name + " <"+formData.Email+">",
            }]
        };

        console.log('data', data);

        try {
            await axios.post('https://api.smtp2go.com/v3/email/send', data);
            setFormStatus(1);
            return true;
        } catch(err) {
            setFormStatus(0);
            return false;
        }
    }

    return (
        <div>
            <Helmet>
                <meta name="description" content="We are committed to safety in the palm of your hand. How can we help you today?" />
                <title>knowtifi : Contact Us</title>
                <link rel="canonical" href="https://knowtifi.com/contact"/>
            </Helmet>
            <div id="splash-sub" className="small contact">
                <SmartContainer>
                    <div className="row">
                        <div className="col-12">
                            <div className="splash-text">
                                <h2 className="k">Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
            <div className="section-padding">
                <SmartContainer>
                    <div id="contact-form">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h2 style={{ marginBottom: '30px' }}><span className="k">Contact knowtifi</span></h2>
                                <h3>We are committed to safety in the palm of your hand.</h3>
                                <h5>How can we help you today?</h5>
                                <br/>
                            </div>
                            <div className="col-12 col-lg-6">
                                { formStatus === -1 ?
                                    <form className="auth-form" onSubmit={handleSubmit(submitForm)}>
                                    <div className="form-wrap">
                                    <div className="input-group form-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-user fa-fw" aria-hidden="true"></i>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={'John Doe'}
                                            {...register("Name", { required: true })}
                                        />
                                    </div>
                                    <div className="input-group form-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-phone fa-fw" aria-hidden="true"></i>
                                        </span>
                                        <input
                                            type="text"
                                            placeholder="jdoe@example.com"
                                            className={`form-control`}
                                            {...register("Email",{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="How can we help you?" {...register("Message", { required: true })} />
                                    </div>

                                        { !_.isEmpty(errors) &&
                                          <div className={'contact-error'}>
                                            Could not send message, form contains errors.
                                          </div>
                                        }
                                    <div className="form-group">
                                        <div>
                                            <span id="submit-button">
                                                <button className="btn" id="submitContact">
                                                    <i className="fa fa-send" aria-hidden="true"></i> &nbsp;Send Your Message
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                    </form>
                                : formStatus === 0 ?
                                    <div className={'alert alert-danger'}>
                                        <b>We're Sorry</b>
                                        <br/>An error occurred while attempting to send this message. Please refresh the page and try again.
                                    </div>
                                : formStatus === 2 ?
                                    <div className={'alert alert-warning'}>
                                        <b>Sending message...</b>
                                    </div>
                                : formStatus === 1 ?
                                    <div className={'alert alert-success'}>
                                        <b>Message Sent!</b>
                                        <br/>Your message has been sent to knowtifi. A customer representative will be in touch with you soon.
                                    </div>
                                : <></>
                                }
                            </div>
                        </div>
                    </div>
                </SmartContainer>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(ContactUs);

